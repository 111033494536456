@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

@import 'components/App.css';
@import '~bulma/css/bulma.min.css';
@import '~react-multi-carousel/lib/styles.css';
/* @import '~bulma-checkradio/dist/css/bulma-checkradio.min.css'; */
@import '~react-toastify/dist/ReactToastify.min.css';
@import '~antd/dist/antd.css';
@import '~font-awesome/css/font-awesome.min.css';
@import '~react-bulma-components/dist/react-bulma-components.min.css';
/* @import '~time-schedule/lib/style/index.css'; */

body {
  font-family: 'Nunito', sans-serif !important;
}

.is-right {
  float: right;
}

.is-borderless {
  border: none !important;
}

.navbar-burger:hover {
  background-color: transparent !important;
}

.navbar-brand {
  /* overflow: scroll !important; */
}

@media (max-width: 420px) {
  .hero .hero-body .box {
    box-shadow: none;
  }
}

@media (min-width: 420px) {
  .hero .hero-body .box {
    padding: 3rem;
  }
}

/* for click on web browser desktop */
.click-zoom input[type=checkbox] {
  display: none
}

.click-zoom img {
  transition: transform 0.25s ease;
  cursor: zoom-in
}

.click-zoom input[type=checkbox]:checked~img {
  transform: translateX(var(--x)) translateY(var(--y)) scale(2);
  cursor: zoom-out
}

/* styles for custom images slider start */
.swiper-pagination-bullet-modal {
  cursor: pointer;
  width: auto;
  margin: 0 5px;
  height: 5vh;
  display: inline-block;
  border-radius: 0%;
  background: transparent;
  opacity: 0.25;
  object-fit: contain;
  display: var(--displayControl);
  border: 1px solid black;
}

.swiper-pagination-bullet-active-modal {
  opacity: 1;
}

.img-modal-content {
  width: 90% !important;
  height: auto
}

.img-modal-content>.swiper-container>.swiper-button-prev,
.img-modal-content>.swiper-container>.swiper-button-next {
  color: #fff;
  display: var(--displayControl);
}

.slider-img {
  width: auto;
  height: 80vh;
  /* border: 1px solid #d4d8df; */
  object-fit: contain
}

.image-modal {
  background-color: rgba(10, 10, 10, .86);
}

.modal-close {
  z-index: 9999;
}

/* path where this class is used  App\src\features\manage-business\src\components\CommunicationChecks */
.message_label {
  width: fit-content;
  padding: 3px 7px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .img-modal-content {
    width: 100% !important;
    height: auto
  }

  .slider-img {
    max-width: 80vw;
  }

  .swiper-pagination-bullet-modal {
    height: 7vh;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 50vh;
  }
}

/* styles for custom images slider end */

/* Campaign View and Details */
.modal-compaign {
  width: 768px !important;
  border-radius: 8px
}

.modal-compaign-body {
  padding: 20px 60px !important
}

.modal-compaign-details-body {
  padding: 45px 60px 25px !important
}

.th-compaign-details {
  border-width: 1px !important;
  color: #363636;
}

@media screen and (max-width: 900px) {
  .modal-compaign {
    width: 98% !important;
    font-size: 90%;
  }

  .modal-compaign-body {
    padding: 3vw !important
  }

  .modal-compaign-details-body {
    padding: 3vw !important
  }

  .modal-compaign .title.is-6 {
    font-size: 90%;
  }

  /* .modal-compaign .py-3.px-2 {
    padding: 12px 0px!important;
  }  */
  .modal-compaign .tag.is-light.has-text-black {
    font-size: 90%;
  }
}

/* Campaign View and Details End */