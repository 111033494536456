a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.is-success {
  background-color: #38761d !important;
}

.blue-color {
  color: #41a6ee;
}

.yellow-color {
  color: #ffaf24;
}

.fade-color {
  color: #dad8d8;
}

.rating-text {
  height: 1rem;
  font-size: 11px;
  padding-left: 5px;
  margin-bottom: 5px;
}

.location-text {
  font-size: 11px;
  font-weight: bold;
}

.button-location {
  border: 1px solid #41a6ee;
  border-radius: 4px !important;
}

.comment-time {
  color: #9d9d9d;
}

.button-location i {
  color: #41a6ee;
}

.button-appointment {
  width: 80%;
}

.button-appointment-update {
  color: #3273dc;
  border: 2px solid #74a5f3;
}

div.tag {
  border: 2px solid #2389e5;
  cursor: pointer;
}

div.tag:hover {
  color: white;
  background-color: #3ec0f1;
}

div.tag-active {
  color: white;
  background-color: #3ec0f1;
}

.has-text-underline {
  text-decoration: underline;
}

.has-text-muted {
  color: rgb(206, 206, 206);
}

::-webkit-scrollbar {
  display: none;
}

#search_input_location::placeholder {
  color: #454241;
  font-size: 15px;
  opacity: 1;
}

#search_input::placeholder {
  font-size: 15px;
  opacity: 1;
}

.react-multi-carousel-list {
  position: unset !important;
}

.react-multiple-carousel__arrow {
  min-width: 30px !important;
  min-height: 30px !important;
  background: #1b6ec2;
  box-shadow: 0 0.5em 1em -0.125em rgb(14 15 15 / 43%),
    0 0 0 3px rgb(10 10 10 / 2%);
}

.react-multiple-carousel__arrow:hover {
  background: rgba(255, 255, 255, 1);
}

.react-multiple-carousel__arrow::before {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}

.react-multiple-carousel__arrow:hover::before {
  color: #000;
}

.react-multiple-carousel__arrow--left {
  left: -15px !important;
}

.react-multiple-carousel__arrow--right {
  right: -15px !important;
}

.react-tel-input>input {
  width: 100% !important;
  height: 40px !important;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.appointment-table-container {
  overflow: unset !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #3298dc;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3298dc;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.is-underline {
  text-decoration: underline !important;
}

.slot-btn {
  width: 78px !important;
}

/* icon-buttons */
.button-icon {
  background-color: rgba(9, 30, 66, 0.03) !important;
  border-radius: 3px !important;
  border: none !important;
  color: rgb(66, 82, 110) !important;
}

.avatar-icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.action-btn {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;
}

.button-icon.is-active {
  background-color: rgba(66, 166, 238, 0.2) !important;
}

.button-icon:hover {
  background-color: rgba(66, 166, 238, 0.2) !important;
  color: rgba(66, 166, 238, 1) !important;
}

.rounded {
  border-radius: 100% !important;
}

/* profile-avatar */
.profile-avatar {
  width: 28px;
  height: 28px;
  border-radius: 100%;
}

#root {
  position: relative;
  min-height: 100vh;
  /* height: 100vh; */
  /* overflow: hidden; */
  padding-bottom: 3.5rem;
  background-color: #f5f5f5 !important;
}

.page-footer {
  background: rgba(0, 0, 0, 1);
  color: white;
  padding-top: 8px;
  padding-bottom: 15px;
  /* position: fixed; */
  position: absolute;
  bottom: 0;
  width: 100%;
  /* margin-top: 50px; */
}

/* Mobile devices */
@media only screen and (max-width: 600px) {
  .footer-copyrights {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .footer-tags {
    font-size: 12px;
  }
}

.swiper-container {
  position: unset;
}

.swiper-pagination {
  bottom: 0 !important;
}

.w-100 {
  width: 100%;
}

.is-borderless {
  border: none !important;
}

.accordion-border {
  border: 8px solid rgba(18, 173, 43, 0.9);
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.is-hoverable {
  cursor: pointer;
}

.is-non-focusable:focus:not(:active) {
  box-shadow: none !important;
}

.is-danger-disabled {
  background-color: #feecf0 !important;
  color: rgba(204, 15, 53, 0.3) !important;
}

.is-disabled-searchbar {
  background-color: #f5f5f5 !important;
}

.react-video-player {
  height: 113px !important;
}

.goog-te-combo {
  position: absolute !important;
  z-index: 9999;
  height: 35px;
  width: 150px;
  border-radius: 100px;
  bottom: 0;
}

#attachments-modal.modal {
  align-items: start;
  flex-direction: row;
  justify-content: end;
}

#attachments-modal .modal-card {
  max-height: 100%;
  min-height: 100%;
  width: 400px;
  margin-right: 0;
}

#attachments-modal .modal-card .modal-card-body {
  background-color: #f5f5f5;
  padding: 0%;
}

.attachment-tab.is-active * {
  color: #3298dc !important;
}

.pagination-btn {
  width: 50px;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns {
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 5px;
  margin: 4px;
  border-radius: 5px;
  border: 1px solid #3399da;
  color: #3399da;
  cursor: pointer;
}

.paginationActive a {
  color: white;
  background-color: #3399da;
}

.paginationDisabled a {
  display: none;
}

.accordion__panel {
  padding: 10px;
}

.mainBox {
  padding: 10px;
}


.addnewholidayBtn:disabled,
.addnewholidayBtn[disabled] {
  background: #84b5cd !important;
  opacity: 1 !important;
}



































@media screen and (min-width: 769px) {
  #attachments-modal .modal-card {
    margin-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .mainSection {
    padding: 3px !important;
  }

  .accordion__panel {
    padding: 5px;
  }

  .mainBox {
    padding: 3px !important;
  }


  #attachments-modal .modal-card {
    width: 100% !important;
    margin: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .react-video-player {
    height: 225px !important;
  }
}

@keyframes blink {
  50% {
    border-color: #ffffff;
  }
}