a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.brand {
  margin-left: 10px;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .brand {
    margin: 0;
  }
  img {
    width: 80px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.SectionHelper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.SubSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profile-badge {
  position: absolute;
  right: 0%;
  border-radius: 100%;
  color: white;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  background-color: #ee2a2a;
  margin-left: -5px;
  margin-top: -13px;
  padding: 1px 6px;
}

.notification-badge {
  position: absolute;
  right: 0;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  width: 30px;
  height: 20px;
  font-size: 10px;
  border: 2px solid white;
  background-color: #ee2a2a;
  margin-top: -70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-badge-mobile {
  position: relative;
  border-radius: 50%;
  color: white;
  width: 12px;
  height: 12px;
  font-size: 8px;
  text-align: center;
  background-color: #ee2a2a;
  margin-left: -25%;
  margin-top: -60%;
}
